import React, { useState, useCallback } from 'react';

import { Form, Button, Typography, Space, Divider } from 'antd';
import MerchantMenuSelector from 'menuManagement/MerchantMenuSelector';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import { replaceReadOnlyMenu } from 'menuManagement/MenuManagementHelpers';

const { Text } = Typography;
const { Item } = Form;

ReplaceReadOnlyMenu.propTypes = {
	merchantId: propTypes.string.isRequired,
};

export default function ReplaceReadOnlyMenu({ merchantId }) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const [menuSelectValue, setMenuSelectValue] = useState('');

	const onFinish = useCallback(async () => {
		try {
			const menuManagementResponse = await replaceReadOnlyMenu(
				merchantId,
				menuSelectValue,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		} catch (e) {
			console.error(`Exception while accessing Menu Management - ${e}`);
			setResponse({ error: 'Exception while accessing Menu Management' });
			setResponseModalVisible(true);
		}
	}, [setResponse, setResponseModalVisible, merchantId, menuSelectValue]);

	const onFinishFailed = useCallback(() => {
		console.error('Invalid UUID');
	}, []);

	return (
		<Form
			name="deleteMenu"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off"
			style={{ width: 600 }}
		>
			<Space direction="vertical" size="small" style={{ width: 600 }}>
				<Text>
					This function will delete the existing Read-Only Menu and replace it
					with the selected menu.
				</Text>
				<Text>
					A copy of the selected menu will will be created and will exist in the
					UI.
				</Text>
			</Space>
			<Divider />
			<Item
				label="Menu to Delete"
				name="menuUuid"
				rules={[
					() => ({
						validator() {
							if (!menuSelectValue.length) {
								return Promise.reject('Select a menu to delete.');
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<MerchantMenuSelector
					setMenuSelectValue={setMenuSelectValue}
					merchantId={merchantId}
					selectType="single"
				/>
			</Item>
			<Button type="primary" htmlType="submit" disabled={!menuSelectValue}>
				Replace Read-Only Menu
			</Button>
			<ResponseModal
				title="Read-Only Menu Result"
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</Form>
	);
}
