import React from 'react';

import { Tabs, Card } from 'antd';
import ViewDeletedMenus from 'menuManagement/ViewDeletedMenus';
import propTypes from 'prop-types';

MenuManagementAdminCompany.propTypes = {
	companyId: propTypes.string.isRequired,
};

export default function MenuManagementAdminCompany({ companyId }) {
	const items = [
		{
			label: 'View & Clear Deleted Menus',
			key: 1,
			children: <ViewDeletedMenus companyId={companyId} />,
		},
	];
	return (
		<Card>
			<Tabs items={items} defaultActiveKey="1" size="small" />
		</Card>
	);
}
