import React, { useState, useEffect, useCallback } from 'react';

import {
	Typography,
	Divider,
	Button,
	Row,
	Col,
	Input,
	Space,
	Select,
	message,
	Popconfirm,
	Tooltip,
} from 'antd';
import {
	createOrchestratorAxiosInstance,
	createMenuMappingAxiosInstance,
	createMenuMappingV2AxiosInstance,
} from 'createAxiosInstance';
import BatchModifierMapping from 'menuManagement/BatchModifierMapping';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';

import {
	MAPPING_ITEM_TYPES,
	SUPPORTED_APPS_COPY,
	SUPPORTED_APPS_REFRESH,
} from 'menuManagement/menuManagementConstants';

const MappingTasks = ({ companyID }) => {
	const [merchantIDMenuManagement, setMerchantIDMenuManagement] =
		useState(null);
	const [merchantIDLive, setMerchantIDLive] = useState(null);
	const [mainItemID, setMainItemID] = useState(null);
	const [apps, setApps] = useState(null);
	const [selectedApps, setSelectedApps] = useState();
	const [sourceCompanyID, setSourceCompanyID] = useState(null);
	const [targetCompanyID, setTargetCompanyID] = useState(null);
	const [sourceIntegration, setSourceIntegration] = useState(null);
	const [targetIntegration, setTargetIntegration] = useState(null);
	const [refreshApp, setRefreshApp] = useState(null);
	const [unPublishItem, setUnPublishItem] = useState(null);
	const [points, setPoints] = useState(null);
	const [itemType, setItemType] = useState(null);

	const { featureFlags } = useAuth();

	const fetchApps = useCallback(async () => {
		const axios = await createMenuMappingAxiosInstance(companyID);
		const {
			data: { data: apps },
		} = await axios.get('/menu/apps');
		setApps(apps);
		setSelectedApps([]);
	}, [companyID]);

	const fetchAppsV2 = useCallback(async () => {
		const axios = await createMenuMappingV2AxiosInstance();
		const {
			data: { data: apps },
		} = await axios.get(`/V2/company/${companyID}/menu/apps`);
		setApps(apps);
		setSelectedApps([]);
	}, [companyID]);

	useEffect(() => {
		(async () => {
			featureFlags?.MENU_MAPPING_V2_API_ENABLED
				? await fetchAppsV2()
				: await fetchApps();
		})();
	}, [fetchApps, fetchAppsV2, featureFlags?.MENU_MAPPING_V2_API_ENABLED]);

	const refreshMenu = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			await axios.post('/mapping/sync/all', {
				company_id: companyID,
				app: refreshApp,
			});
			message.success(
				`Refreshed menu management menus for company ID: ${companyID}.`,
			);
		} catch (err) {
			console.error(`Error when refreshing menus: ${err}`);
		}
	}, [companyID, refreshApp]);

	const handleSelectApp = (value) => setSelectedApps(value);

	const fetchAllMenusLive = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			const {
				data: { tasks },
			} = await axios.post('/all', {
				company_id: companyID,
				operation: 'get_all_menus',
				app: 'all',
			});
			message.success(`Started fetching all menus. Tasks: [${tasks}]`);
		} catch (err) {
			console.error(`Error when fetching menus: ${err}`);
		}
	}, [companyID]);

	const fetchAllMenusMenuManagement = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			await axios.post('/mm/push', {
				company_id: companyID,
				merchant_id: merchantIDMenuManagement,
				menu_uuids: [],
				destinations: [
					{
						app: 'cuboh_mapping',
						locations: [{ id: '' }],
					},
				],
			});
			message.success(
				`Started fetching menu management menus for Merchant ID: ${merchantIDMenuManagement}.`,
			);
		} catch (err) {
			console.error(`Error when fetching menus: ${err}`);
		}
	}, [companyID, merchantIDMenuManagement]);

	const fetchLiveMenusByMerchant = useCallback(async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			const {
				data: { tasks },
			} = await axios.post('/all', {
				company_id: companyID,
				merchant_id: merchantIDLive,
				operation: 'get_all_menus',
				app: 'all',
			});
			message.success(`Started fetching all menus. Tasks: [${tasks}]`);
		} catch (err) {
			console.error(`Error when fetching menus: ${err}`);
		}
	}, [companyID, merchantIDLive]);

	const fetchAppMenus = async () => {
		const axios = await createOrchestratorAxiosInstance();
		const {
			data: { tasks },
		} = await axios.post('/app', {
			company_id: companyID,
			operation: 'get_app_menu',
			app: 'apps',
		});

		message.success(`Started fetching app menus. Tasks: [${tasks}].`);
	};

	const copyMappingCompany = async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			await axios.post(`mapping/copymapping/`, {
				company_id_from: sourceCompanyID,
				company_id_to: targetCompanyID,
				operation: 'copy_mapping',
				app: 'all',
			});
			message.success(
				`Company mapping copied from ${sourceCompanyID} to ${targetCompanyID}.`,
			);
		} catch (e) {
			message.error('An error occurred while copying company mapping.');
		}
	};

	const copyMappingIntegration = async () => {
		const axios = await createOrchestratorAxiosInstance();
		try {
			await axios.post(`mapping/copymapping/`, {
				company_id_from: `${companyID}`,
				company_id_to: `${companyID}`,
				operation: 'copy_mapping',
				app: `${sourceIntegration}--/--${targetIntegration}`,
			});
			message.success(
				`Integration mapping copied from ${sourceIntegration} to ${targetIntegration} for Company ID: ${companyID}.`,
			);
		} catch (e) {
			message.error('An error occurred while copying company mapping.');
		}
	};

	const deleteMenus = async () => {
		const axios = await createMenuMappingAxiosInstance(companyID);
		const {
			data: { data: updatedApps },
		} = await axios.delete('/menu', { data: { apps: selectedApps } });
		setApps(updatedApps);
		setSelectedApps([]);
	};

	const unPublish = async () => {
		try {
			const axios = await createOrchestratorAxiosInstance();
			await axios.post(`/mapping/${companyID}/main/update/match`, {
				record_id: unPublishItem,
				values: { matched: false },
			});
		} catch (e) {
			console.error(e);
		}
	};

	const massMatchMains = async () => {
		const axios = await createOrchestratorAxiosInstance();
		await axios.post(`/mapping/${companyID}/main/massupdate/match`, {
			values: { point: points },
		});
	};

	const massMatchMods = async () => {
		const axios = await createOrchestratorAxiosInstance();
		await axios.post(`/mapping/${companyID}/modifier/massupdate/match`, {
			values: { point: points },
		});
	};

	const unMatchItemsOfType = async () => {
		const axios = await createOrchestratorAxiosInstance();
		await axios.post(`/mapping/${companyID}/${itemType}/massupdate/unmatch`);
	};

	const fetchPosMenu = async () => {
		const axios = await createOrchestratorAxiosInstance();
		const {
			data: { tasks },
		} = await axios.post('/pos', {
			company_id: companyID,
			operation: 'get_pos_menu',
			app: 'pos',
		});

		message.success(`Started fetching pos menus. Tasks: [${tasks}].`);
	};

	const mainMatch = async () => {
		const axios = await createOrchestratorAxiosInstance();
		const {
			data: { tasks },
		} = await axios.post('/map', {
			company_id: companyID,
			operation: 'main_matcher',
			app: 'main',
		});

		message.success(`Started main match. Tasks: [${tasks}].`);
	};

	const modMatch = async () => {
		const axios = await createOrchestratorAxiosInstance();
		const {
			data: { tasks },
		} = await axios.post('/map', {
			company_id: companyID,
			operation: 'mod_matcher',
			app: 'mod',
			...{
				...(mainItemID ? { main_item_id: mainItemID } : {}),
			},
		});

		message.success(`Started mod match. Tasks: [${tasks}].`);
	};

	const migrate = async () => {
		const axios = await createMenuMappingAxiosInstance(companyID);
		await axios.post('/publish', {
			company_id: companyID,
			operation: 'migrator',
			app: 'migrate',
		});
		message.success(`Started migrate task.`);
	};

	return (
		<>
			<Typography.Title level={2}>Background Tasks</Typography.Title>
			<Divider />
			<Row>
				<Col span={18}>
					<Typography.Text strong>Fetch All Live Store Menus:</Typography.Text>{' '}
					Fetch all the app menus & the POS menu then store them into the
					staging database.
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to fetch all Live Store menus for company ${companyID}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={fetchAllMenusLive}
					>
						<Button type="link">Fetch All Menus From Live Store</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>
						Fetch All Menu Management Menus:
					</Typography.Text>{' '}
					Fetch all the app menus & the POS menu then store them into the
					staging database. Must be done by merchant ID.
				</Col>
				<Col span={4}>
					<Input
						placeholder="Merchant ID"
						onChange={(e) => setMerchantIDMenuManagement(e.target.value)}
						size="small"
					/>
				</Col>
				<Col span={4}>
					<Space direction={'vertical'}>
						<Tooltip
							placement="topLeft"
							title={!merchantIDMenuManagement && `Merchant ID is required.`}
						>
							<Button
								type="link"
								onClick={fetchAllMenusMenuManagement}
								disabled={!merchantIDMenuManagement}
							>
								Fetch All Menus From Menu Management
							</Button>
						</Tooltip>
					</Space>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>
						Fetch All Live Menus for a Specific Merchant:
					</Typography.Text>{' '}
					Fetch all the app menus & the POS menu then store them into the
					staging database. Must be done by merchant ID.
				</Col>
				<Col span={4}>
					<Input
						placeholder="Merchant ID"
						onChange={(e) => setMerchantIDLive(e.target.value)}
						size="small"
					/>
				</Col>
				<Col span={4}>
					<Space direction={'vertical'}>
						<Tooltip
							placement="topLeft"
							title={!merchantIDLive && `Merchant ID is required.`}
						>
							<Button
								type="link"
								onClick={fetchLiveMenusByMerchant}
								disabled={!merchantIDLive}
							>
								Fetch All Menus From Live Store
							</Button>
						</Tooltip>
					</Space>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={18}>
					<Typography.Text strong>Fetch App Menus:</Typography.Text> Fetch all
					the app menus then store them into the staging database.
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to fetch all app menus for company ${companyID}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={fetchAppMenus}
					>
						<Button type="link">Fetch App Menus </Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={18}>
					<Typography.Text strong>Fetch POS Menu:</Typography.Text> Fetch the
					POS menu then store it into the staging database.
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to fetch the POS menu for company ${companyID}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={fetchPosMenu}
					>
						<Button type="link">Fetch POS Menu</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={18}>
					<Typography.Text strong>Main Match:</Typography.Text> Match each of
					the app main items to the best choice on the pos main item.
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want run main-match for company ${companyID}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={mainMatch}
					>
						<Button type="link">Run Main Match</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>Modifier Match:</Typography.Text> Match each
					of the app modifier to the best choice of pos modifier. Optionally
					provide a main item ID to run it only for a specific main.
				</Col>
				<Col span={4}>
					<Input
						placeholder="Main Item ID"
						onChange={(e) => setMainItemID(e.target.value)}
						value={mainItemID}
						size="small"
					/>
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want run mod-match for ${
							mainItemID
								? `${mainItemID}?`
								: `all modifiers on company ${companyID}`
						}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={modMatch}
					>
						<Button type="link">Run Mod Match</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>Refresh/Sync App Menu:</Typography.Text>{' '}
					Update menu with any changes that have been made to the menu on the
					app’s public store menu.
				</Col>
				<Col span={4}>
					<Select
						options={SUPPORTED_APPS_REFRESH}
						onChange={(value) => {
							setRefreshApp(value);
						}}
						placeholder={'Please select an app'}
					/>
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure refresh menus for ${refreshApp}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={refreshMenu}
					>
						<Button type="link" disabled={!refreshApp}>
							Refresh Menu
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={18}>
					<Typography.Text strong>Migrate:</Typography.Text> Migrate the mapping
					from staging to production (same as publish on dashboard).
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want run migrate for ${companyID}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={migrate}
					>
						<Button type="link">Migrate</Button>
					</Popconfirm>
				</Col>
				<Divider />
				<Col span={14}>
					<Typography.Text strong>Copy Mapping:</Typography.Text> Copy mapping
					from one company to another. Usage: Enter Company ID to copy from and
					Company ID to copy to.
				</Col>
				<Col span={4}>
					<Space direction="vertical">
						<Input
							placeholder="Source Company ID"
							onChange={(e) => setSourceCompanyID(e.target.value)}
							value={sourceCompanyID}
							size="small"
						/>

						<Input
							placeholder="Target Company ID"
							onChange={(e) => setTargetCompanyID(e.target.value)}
							value={targetCompanyID}
							size="small"
						/>
					</Space>
				</Col>
				<Col span={4}>
					<Button type="link" disabled={!(sourceCompanyID && targetCompanyID)}>
						<Popconfirm
							title={`Are you sure you want to copy mapping from Company ID: ${sourceCompanyID} to Company ID: ${targetCompanyID}`}
							okText="Yes"
							cancelText="No"
							onConfirm={copyMappingCompany}
						>
							Copy Mapping to Company
						</Popconfirm>
					</Button>
				</Col>
				<Divider />
				<Col span={14}>
					<Typography.Text strong>Copy Mapping:</Typography.Text> Copy mapping
					from one integration to another for this company. Usage: Select a
					Source Integration (from) and a Target Integration (to).
				</Col>
				<Col span={4}>
					<Space direction="vertical">
						<Select
							options={SUPPORTED_APPS_COPY}
							onChange={(value) => {
								setSourceIntegration(value);
							}}
							placeholder={'Select SOURCE'}
						/>
						<Select
							options={SUPPORTED_APPS_COPY}
							onChange={(value) => {
								setTargetIntegration(value);
							}}
							placeholder={'Select TARGET'}
						/>
					</Space>
				</Col>
				<Col span={4}>
					<Button
						type="link"
						disabled={!(sourceIntegration && targetIntegration && companyID)}
					>
						<Popconfirm
							title={`Are you sure you want to copy mapping from ${sourceIntegration} to ${targetIntegration} for Company ID: ${companyID}`}
							okText="Yes"
							cancelText="No"
							onConfirm={copyMappingIntegration}
						>
							Copy Mapping to App
						</Popconfirm>
					</Button>
				</Col>
				<Divider />

				<Col span={14}>
					<Typography.Text strong>Delete App Menu:</Typography.Text> Delete all
					mains and modifiers relating to a specific app on staging.
				</Col>
				<Col span={4}>
					<Select
						mode="multiple"
						allowClear
						placeholder="Please select app menus to delete."
						style={{ width: '100%' }}
						onChange={handleSelectApp}
						size="small"
						value={selectedApps}
					>
						{apps?.map((app) => (
							<Select.Option key={app}>{app}</Select.Option>
						))}
					</Select>
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to delete all menu items and modifiers for ${selectedApps}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={deleteMenus}
					>
						<Button disabled={selectedApps?.length === 0} type="link">
							Delete Menu
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>Un-Publish an Item:</Typography.Text>
				</Col>
				<Col span={4}>
					<Input
						placeholder="Enter item UUID"
						onChange={(e) => setUnPublishItem(e.target.value)}
						value={unPublishItem}
						size="small"
					/>
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to un-publish item ${unPublishItem}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={unPublish}
					>
						<Button type="link">Un-Publish Item</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>
						Mass Match: Enter mapping score to Mass Match (Up to 400):
					</Typography.Text>
				</Col>
				<Col span={4}>
					<Input
						placeholder="Enter confidence points"
						onChange={(e) => setPoints(e.target.value)}
						value={points}
						size="small"
					/>
				</Col>
				<Col span={4}>
					<Space direction="vertical">
						<Button type="link" onClick={massMatchMains}>
							Mass Match Mains
						</Button>

						<Button type="link" onClick={massMatchMods}>
							Mass Match Modifiers
						</Button>
					</Space>
				</Col>
			</Row>
			<Divider />
			<Row>
				<BatchModifierMapping companyId={companyID} />
			</Row>
			<Divider />
			<Row>
				<Col span={14}>
					<Typography.Text strong>
						Set Matched=False - Mark all items of the selected type as
						unmatched:
					</Typography.Text>
				</Col>
				<Col span={4}>
					<Select
						options={MAPPING_ITEM_TYPES}
						onChange={(value) => {
							setItemType(value);
						}}
						placeholder={'Please select an item type'}
					/>
				</Col>
				<Col span={4}>
					<Popconfirm
						title={`Are you sure you want to un-match all items of type ${itemType}?`}
						okText="Yes"
						cancelText="No"
						onConfirm={unMatchItemsOfType}
					>
						<Button type="link">Mark as matched=false</Button>
					</Popconfirm>
				</Col>
			</Row>
		</>
	);
};

MappingTasks.propTypes = {
	companyID: PropTypes.number.isRequired,
};

export default MappingTasks;
