import React from 'react';

import { Tabs } from 'antd';
import BulkUpdateMenuPrices from 'menuManagement/BulkUpdateMenuPrices';
import ConvertImages from 'menuManagement/ConvertImages';
import DeleteMenuFromApp from 'menuManagement/DeleteMenuFromApp';
import DeleteMenuFull from 'menuManagement/DeleteMenuFull';
import DeleteMenuManagementItem from 'menuManagement/DeleteMenuManagementItem';
import DuplicateMenu from 'menuManagement/DuplicateMenu';
import InspectMenuManagementItem from 'menuManagement/InspectMenuManagementItem';
import LinkAppToMenu from 'menuManagement/LinkAppToMenu';
import MatchMappingMenuManagement from 'menuManagement/MatchMappingMenuManagement';
import PublishMenu from 'menuManagement/PublishMenu';
import RemoveMenuOrphans from 'menuManagement/RemoveMenuOrphans';
import RemoveReadOnlyMenus from 'menuManagement/RemoveReadOnlyMenus';
import ReplaceReadOnlyMenu from 'menuManagement/ReplaceReadOnlyMenu';
import RestoreMenu from 'menuManagement/RestoreMenu';
import RunMenuFixer from 'menuManagement/RunMenuFixer';
import SyncMenuProperty from 'menuManagement/SyncMenuProperty';
import TriggerMenuCache from 'menuManagement/TriggerMenuCache';
import ViewDeletedItemsFromMenu from 'menuManagement/ViewDeletedItemsFromMenu';
import ViewDeletedMenus from 'menuManagement/ViewDeletedMenus';
import propTypes from 'prop-types';

MenuManagementAdminMerchant.propTypes = {
	merchantId: propTypes.string.isRequired,
	companyId: propTypes.string.isRequired,
	doorDashCredentials: propTypes.string,
};

export default function MenuManagementAdminMerchant({
	merchantId,
	companyId,
	doorDashCredentials,
}) {
	const items = [
		{
			label: 'Inspect Item',
			key: 1,
			children: <InspectMenuManagementItem merchantId={merchantId} />,
		},
		{
			label: 'Duplicate a Menu',
			key: 2,
			children: <DuplicateMenu merchantId={merchantId} companyId={companyId} />,
		},
		{
			label: 'Trigger menu Cache',
			key: 3,
			children: (
				<TriggerMenuCache merchantId={merchantId} companyId={companyId} />
			),
		},
		{
			label: 'Restore Delete Menu',
			key: 4,
			children: <RestoreMenu merchantId={merchantId} />,
		},
		{
			label: 'View & Clear Deleted Menus',
			key: 5,
			children: <ViewDeletedMenus merchantId={merchantId} />,
		},
		{
			label: 'View Deleted Items',
			key: 6,
			children: <ViewDeletedItemsFromMenu merchantId={merchantId} />,
		},
		{
			label: 'Delete Item',
			key: 7,
			children: <DeleteMenuManagementItem merchantId={merchantId} />,
		},
		{
			label: 'Add App to Menu',
			key: 8,
			children: <LinkAppToMenu merchantId={merchantId} />,
		},
		{
			label: 'Publish a Menu',
			key: 9,
			children: <PublishMenu merchantId={merchantId} companyId={companyId} />,
		},
		{
			label: 'Bulk Update Menu Prices',
			key: 10,
			children: <BulkUpdateMenuPrices merchantId={merchantId} />,
		},
		{
			label: 'Delete Menu from App',
			key: 11,
			children: (
				<DeleteMenuFromApp
					merchantId={merchantId}
					doorDashCredentials={doorDashCredentials}
				/>
			),
		},
		{
			label: 'Permanently Delete Entire Menu',
			key: 12,
			children: <DeleteMenuFull merchantId={merchantId} />,
		},
		{
			label: 'Match Mapping and MM Items',
			key: 13,
			children: (
				<MatchMappingMenuManagement
					merchantId={merchantId}
					companyId={companyId}
				/>
			),
		},
		{
			label: 'Remove Read Only Menus',
			key: 14,
			children: (
				<RemoveReadOnlyMenus merchantId={merchantId} companyId={companyId} />
			),
		},
		{
			label: 'Replace Read-Only Menu',
			key: 15,
			children: <ReplaceReadOnlyMenu merchantId={merchantId} />,
		},
		{
			label: 'Convert Images to .webp',
			key: 16,
			children: <ConvertImages merchantId={merchantId} />,
		},
		{
			label: 'Remove Menu Orphans',
			key: 17,
			children: (
				<RemoveMenuOrphans companyId={companyId} merchantId={merchantId} />
			),
		},
		{
			label: 'Sync Menu Property',
			key: 18,
			children: <SyncMenuProperty merchantId={merchantId} />,
		},
		{
			label: 'Run Menu Fixer',
			key: 19,
			children: <RunMenuFixer companyId={companyId} merchantId={merchantId} />,
		},
	];

	return (
		<Tabs
			items={items}
			defaultActiveKey="1"
			size="small"
			tabPosition={'left'}
		/>
	);
}
