import React, { useState, useCallback } from 'react';

import { Button, Typography, Space, Popconfirm, Divider } from 'antd';
import ResponseModal from 'menuManagement/ResponseModal';
import propTypes from 'prop-types';

import {
	fetchDeletedMenus,
	fetchDeletedMenusForCompany,
	clearDeletedMenus,
	clearDeletedMenusForCompany,
} from 'menuManagement/MenuManagementHelpers';

const { Text } = Typography;

ViewDeletedMenus.propTypes = {
	merchantId: propTypes.string,
	companyId: propTypes.string,
};

export default function ViewDeletedMenus({
	companyId = null,
	merchantId = null,
}) {
	const [responseModalVisible, setResponseModalVisible] = useState(false);
	const [response, setResponse] = useState({});
	const showDeleted = useCallback(async () => {
		if (companyId) {
			const menuManagementResponse = await fetchDeletedMenusForCompany(
				companyId,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		} else {
			const menuManagementResponse = await fetchDeletedMenus(merchantId);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		}
	}, [setResponse, setResponseModalVisible, merchantId, companyId]);

	const permanentlyDeleteMenus = useCallback(async () => {
		if (companyId) {
			const menuManagementResponse = await clearDeletedMenusForCompany(
				companyId,
			);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		} else {
			const menuManagementResponse = await clearDeletedMenus(merchantId);
			setResponse(menuManagementResponse);
			setResponseModalVisible(true);
		}
	}, [setResponse, setResponseModalVisible, merchantId, companyId]);

	if (!merchantId && !companyId) {
		return null;
	}

	return (
		<Space direction="vertical" size="small" style={{ width: 600 }}>
			<Text>{`Click "View" to view all the deleted menus of this ${
				merchantId ? 'merchant' : 'company'
			}`}</Text>
			<Button type="primary" onClick={showDeleted}>
				View
			</Button>
			<Divider />
			<Text>
				{`Click "Permanently Delete" to remove all the DELETED menus of this ${
					merchantId ? 'merchant' : 'company'
				} from the database`}
			</Text>
			<Popconfirm
				title="This delete operation cannot be undone. Are you sure?"
				onConfirm={permanentlyDeleteMenus}
				okText="Delete"
				cancelText="Cancel"
			>
				<Button type="primary">Permanently Delete</Button>
			</Popconfirm>
			<ResponseModal
				title={`Deleted Menus from ${merchantId ? 'Merchants' : 'Companies'}`}
				response={response}
				responseModalVisible={responseModalVisible}
				setResponseModalVisible={setResponseModalVisible}
			/>
		</Space>
	);
}
