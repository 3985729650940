export const menuManagementItemTypes = {
	menu: 'CUBOH_MENU',
	category: 'CUBOH_CATEGORY',
	mainItem: 'CUBOH_MAIN_ITEM',
	modifierGroup: 'CUBOH_MODIFIER_GROUP',
	modifier: 'CUBOH_MODIFIER',
};

export const SUPPORTED_APPS_DELETE = [{ label: 'DoorDash', value: 'doordash' }];

export const READABLE_POS_NAME = {
	omnivore: 'Omnivore',
	squareapp: 'Square',
	clover: 'Clover',
	volante: 'Volante',
	silverware: 'Silverware',
	often: 'Often',
	givex: 'Givex',
	mdproxy: 'MD',
	shooterpp: 'PixelPoint',
	revel: 'Revel',
};

export const SUPPORTED_APPS_REFRESH = [
	{ label: 'DoorDash', value: 'doordash' },
	{ label: 'UberEats', value: 'uber' },
	{ label: 'GrubHub', value: 'grubhub' },
	{ label: 'Ritual', value: 'ritual' },
	{ label: 'ChowNow', value: 'chownow' },
	{ label: 'SkipTheDishes', value: 'skip' },
	{ label: 'Sociavore', value: 'sociavore' },
	{ label: 'AllSet', value: 'allset' },
	{ label: 'FlipDish', value: 'flipdish' },
];

export const MAPPING_ITEM_TYPES = [
	{ label: 'Main Items', value: 'main' },
	{ label: 'Modifiers', value: 'modifier' },
];

export const SUPPORTED_APPS_COPY = [
	{ label: 'DoorDash', value: 'DOORDASH' },
	{ label: 'UberEats', value: 'UBEREATS' },
	{ label: 'GrubHub', value: 'GRUBHUB' },
	{ label: 'Ritual', value: 'RITUAL' },
	{ label: 'ChowNow', value: 'CHOWNOW' },
	{ label: 'SkipTheDishes', value: 'SKIP' },
	{ label: 'Sociavore', value: 'SOCIAVORE' },
	{ label: 'AllSet', value: 'ALLSET' },
	{ label: 'FlipDish', value: 'FLIPDISH' },
	{ label: 'Cuboh', value: 'CUBOH' },
	{ label: 'Caviar', value: 'CAVIAR' },
	{ label: 'FreshBytes', value: 'FRESHBYTES' },
	{ label: 'MixBowl', value: 'MIXBOWL' },
	{ label: 'EatStreet', value: 'EATSTREET' },
	{ label: 'Moduurn', value: 'MODUURN' },
	{ label: 'SmoothCommerce', value: 'SMOOTHCOMMERCE' },
];

export const SUPPORTED_APPS_STATUS_UPDATE = [
	{ label: 'DoorDash', value: 'doordash' },
	{ label: 'Ritual', value: 'ritual' },
	{ label: 'ChowNow', value: 'chownow' },
	{ label: 'SkipTheDishes', value: 'skip' },
	{ label: 'FlipDish', value: 'flipdish' },
];

export const INTEGRATION_STATUSES = [
	{ label: 'Active', value: 'active' },
	{ label: 'Inactive', value: 'inactive' },
	{ label: 'Pending', value: 'pending' },
];

export const READABLE_APPS_NAME = {
	doordash: 'DoorDash',
	ubereats: 'UberEats',
	grubhub: 'GrubHub',
	ritual: 'Ritual',
	chownow: 'ChowNow',
	skip: 'SkipTheDishes',
	allset: 'AllSet',
	cuboh: 'Cuboh',
	flipdish: 'FlipDish',
};

export const EXTRA_SUPPORTED_APPS_MOD_MAPPING = [
	{ label: 'Sociavore', value: 'SOCIAVORE' },
	{ label: 'SmoothCommerce', value: 'SMOOTHCOMMERCE' },
	{ label: 'MixBowl', value: 'MIXBOWL' },
	{ label: 'EatStreet', value: 'EATSTREET' },
	{ label: 'Moduurn', value: 'MODUURN' },
	{ label: 'ChowNow', value: 'CHOWNOW' },
	{ label: 'All', value: '*' },
];

export const INTEGRATION_STATUS = {
	PUBLISHED: '#79eea1',
	MODIFIED: '#faad14',
	IN_PROGRESS: '#6eacd9',
	FAILED: '#fa7a68',
};
